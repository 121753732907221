<template>
  <v-container>
    <v-row justify="center">
      <v-col
        v-for="option in options"
        :key="option.subtitle"
        :cols="option.flex"
        xs="12"
        md="6"
        lg="4"
      >
        <v-card
          :to="{ name: 'templates', query: { default: option.default } }"
          class="align-self-center"
        >
          <v-card-title class="text-center">
            <v-list-item-title class="headline">{{
              option.title
            }}</v-list-item-title>
          </v-card-title>
          <v-img
            :src="option.src"
            height="45vh"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
          ></v-img>
          <v-card-text class="text-center">{{ option.subtitle }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { SERVICE_URL } from "../../js/constants";

export default {
  name: "index",
  methods: {
    ...mapActions("templates", {
      ping: "ping",
    }),
    ...mapActions("notifications", ["error"]),
  },
  data: () => ({
    options: [
      {
        title: "Mis plantillas",
        subtitle: "Sus plantillas personalizadas",
        src: `https://${SERVICE_URL}/pictures/default/plantillas-pro.png`,
        flex: 12,
        default: false,
      },
      {
        title: "Plantillas predefinidas",
        subtitle: "Encuentre inspiración para sus mensajes",
        src: `https://${SERVICE_URL}/pictures/default/plantillas-pre.png`,
        flex: 12,
        default: true,
      },
    ],
  }),
  async created() {
    try {
      await this.ping();
      this.$store.dispatch("setTitle", this.$t("views.app.title"));
    } catch (error) {
      this.error(error.message);
    }
  },
};
</script>

<style></style>
